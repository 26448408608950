@import 'sliders.css';
@tailwind base;
@tailwind components;

/* roboto-regular - latin_cyrillic */
@font-face {
  font-family: 'Roboto';
  font-style: normal;
  font-weight: 400;
  font-display: swap;
  src: local(''),
  url('/fonts/roboto-v30-latin_cyrillic-regular.woff2') format('woff2'), /* Chrome 26+, Opera 23+, Firefox 39+ */
  url('/fonts/roboto-v30-latin_cyrillic-regular.woff') format('woff'); /* Chrome 6+, Firefox 3.6+, IE 9+, Safari 5.1+ */
}
/* roboto-500 - latin_cyrillic */
@font-face {
  font-family: 'Roboto';
  font-style: normal;
  font-weight: 500;
  font-display: swap;
  src: local(''),
  url('/fonts/roboto-v30-latin_cyrillic-500.woff2') format('woff2'), /* Chrome 26+, Opera 23+, Firefox 39+ */
  url('/fonts/roboto-v30-latin_cyrillic-500.woff') format('woff'); /* Chrome 6+, Firefox 3.6+, IE 9+, Safari 5.1+ */
}
/* roboto-700 - latin_cyrillic */
@font-face {
  font-family: 'Roboto';
  font-style: normal;
  font-weight: 700;
  font-display: swap;
  src: local(''),
  url('/fonts/roboto-v30-latin_cyrillic-700.woff2') format('woff2'), /* Chrome 26+, Opera 23+, Firefox 39+ */
  url('/fonts/roboto-v30-latin_cyrillic-700.woff') format('woff'); /* Chrome 6+, Firefox 3.6+, IE 9+, Safari 5.1+ */
}


html,
body {
  font-family: 'Roboto', sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  background: white;
}

body {
  /*overflow: hidden;*/
}
img {
  max-width: 100%;
  max-height: 100%;
}


ymaps[class$='balloon'] {
  border: none !important;
  box-shadow: unset !important;
}

ymaps[class$='balloon__content'] {
  margin-right: 0 !important;
  padding: 0 !important;
  padding-bottom: 30px !important;
}

ymaps[class$='balloon__content'] ymaps {
  width: 100% !important;
}

ymaps[class$='balloon__layout'] {
  background: #ffffff;
  border-radius: 3px;
  padding: 20px;
  padding-bottom: 30px;
  height: 116px;
}

ymaps[class$='balloon__close'] {
  display: none;
}

ymaps[class$='balloon__tail']::after {
  /*background: red;*/
  box-shadow: unset;
}

@media (max-width: 714px) {
  ymaps[class$='balloon__content'] {
    padding-bottom: 0 !important;
  }

  ymaps[class$='balloon__content'] ymaps {
    padding: 15px;
    width: 90% !important;
    max-height: 150px !important;
  }
}

@media (max-width: 650px) {
  ymaps[class$='balloon__content'] {
    padding-bottom: 25px !important;
  }

  ymaps[class$='balloon__content'] ymaps {
    padding: 0;
    width: auto;
    max-height: none !important;
  }

  ymaps[class$='balloon__layout'] {
    width: 100%;
  }
}

@media (max-width: 448px) {
  ymaps[class$='balloon__content'] ymaps {
    width: 100% !important;
  }

  ymaps[class$='balloon__content'] {
    padding: 20px !important;
  }
}

.yMapBalloonTitle {
  margin-bottom: 15px;
  font-family: 'Roboto';
  font-style: normal;
  font-weight: 500;
  font-size: 20px;
  line-height: 120%;
  color: #302e30;
}

.yMapBalloonItemFlex {
  margin-top: 10px;
  display: flex;
}

.yMapBalloonItemFlex > div:not(:first-child) {
  margin-left: 16px;
}

.yMapBalloonItem {
  display: flex;
}

.yMapBalloonItemText {
  margin-left: 10px;
  font-family: 'Roboto';
  font-style: normal;
  font-weight: 400;
  font-size: 13px;
  line-height: 140%;
  color: #686a70;
}

.yMapBalloonItemText.geo {
  width: max-content;
}

.hidden {
  display: none;
}

h1,
h2,
h3,
h4,
h5,
h6 {
  @apply text-gray-900;
  @apply font-semibold;
}

h1 {
  @apply text-h1;
}

h2 {
  @apply text-24px;
}

h3 {
  @apply text-18px;
}

h4 {
  @apply text-17px font-bold;
}

h5 {
  @apply text-14px;
}

h6 {
  @apply text-13px;
}

input[type='search'],
input,
button {
  -webkit-appearance: none;
}

.menuIcon {
  width: 26px;
  height: auto;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: center;
}

.bar {
  width: 13px;
  height: 2px;
  display: flex;
  transition: all 0.25s ease-in-out;
  @apply bg-gray-900;
  @apply rounded-sm;
}

.menu-footer-icon .bar {
  @apply bg-gray-700;
}

.mobile-footer-shadow {
  box-shadow: 0 -2px 4px rgba(0, 0, 0, 0.12);
}

.bar:nth-child(2) {
  width: 26px;
  margin: 4px 0;
}

.bar:last-child {
  width: 18px;
}

.menuBtn:hover .bar:first-child {
  width: 26px;
}

.menuBtn:hover .bar:nth-child(2) {
  width: 13px;
}

.menu-item.active:before {
  content: '';
  width: 3px;
  height: 100%;
  display: flex;
  position: absolute;
  top: 0;
  left: 0;
  bottom: 0;
  @apply bg-gray-900;
}

/* Main Content */
.main-content {
  display: flex;
  flex-direction: column;
  width: 100%;
  min-height: 100vh;
}

/* ScrollBar Height Calc */
.cart-scrollbar {
  max-height: calc(100vh - 233px);
  -webkit-overflow-scrolling: touch;
}

.checkout-scrollbar {
  max-height: calc(100vh - 182px);
  -webkit-overflow-scrolling: touch;
}

.details-scrollbar {
  max-height: calc(100vh - 182px);
  -webkit-overflow-scrolling: touch;
}

.menu-scrollbar {
  max-height: calc(100vh - 138px);
  -webkit-overflow-scrolling: touch;
}

/* footer-old Social icons */
.social {
  color: #999999;
  margin-right: 30px;
  transition: all 0.2s ease-in-out;
}

.social:last-child {
  margin-right: 0;
}

.social.vk:hover {
  color: #4680c2;
}

.social.facebook:hover {
  color: #1877f2;
}

.social.twitter:hover {
  color: #1da1f2;
}

.social.ok:hover {
  color: #ee8208;
}

.social.youtube:hover {
  color: #ff0000;
}

.social.instagram:hover {
  color: #e4405f;
}

/* Carousel Css */
@screen lg {
  .custom-react-multi-carousel-dot-list {
    position: absolute;
    width: auto;
    right: auto;
    bottom: 54px;
    left: 160px;
  }
}

/* Cart */
.drawer {
  width: 100%;
  height: 100%;
  max-width: 450px;
  z-index: 22;
  bottom: 0;

  @apply w-full;
  @apply flex;
  @apply flex-col;
  @apply bg-white;
  @apply shadow-cart;
  @apply fixed;
  @apply transition;
  @apply duration-350;
  @apply ease-in-out;
}

.drawer-cart {
  right: 0;
  transform: translate(450px, 0);
}

.drawer-menu {
  left: 0;
  transform: translate(-450px, 0);
}

.drawer-cart.open,
.drawer-menu.open {
  transform: translate(0, 0);
}

.overlay {
  background-color: rgba(0, 0, 0, 0.35);
  z-index: 21;
  @apply w-full;
  @apply h-full;
  @apply flex;
  @apply fixed;
  @apply top-0;
  @apply left-0;
}

.component-overlay {
  background-color: rgba(0, 0, 0, 0.4);
  z-index: 1;
  @apply w-full;
  @apply h-full;
  @apply flex;
  @apply absolute;
  @apply top-0;
  @apply left-0;
}

/* About Us */
.page-title:after {
  content: '';
  position: relative;
  bottom: -5px;
  left: 0;
  width: 30px;
  height: 2px;
  display: flex;
  @apply bg-gray-900;
}

.about-img-grid img {
  width: 100%;
  height: 100%;
  display: flex;
}

.scrollbar-w-2::-webkit-scrollbar {
  width: 0.25rem;
  height: 0.25rem;
}

.scrollbar-track-blue-lighter::-webkit-scrollbar-track {
  --bg-opacity: 1;
  background-color: #f7fafc;
  background-color: rgba(247, 250, 252, var(--bg-opacity));
}

.scrollbar-thumb-blue::-webkit-scrollbar-thumb {
  --bg-opacity: 1;
  background-color: #edf2f7;
  background-color: rgba(237, 242, 247, var(--bg-opacity));
}

.scrollbar-thumb-rounded::-webkit-scrollbar-thumb {
  border-radius: 0.25rem;
}

.play-btn {
  position: absolute;
  top: calc(50%);
  left: 50%;
  margin-left: -12px;
  margin-top: -12px;
  background: #fff;
  border-radius: 50%;
}

.gradient-text,
.text-fit-gradient * {
  /* Fallback: Set a background color. */
  background-color: red;

  /* Create the gradient. */
  background-image: linear-gradient(45deg, #e04c8a, #5962f3);

  /* Set the background size and repeat properties. */
  background-size: 100%;
  background-repeat: repeat;

  /* Use the text as a mask for the background. */
  /* This will show the gradient as a text color rather than element bg. */
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  /*noinspection CssInvalidPropertyValue*/
  -moz-background-clip: text;
  /*noinspection CssUnknownProperty*/
  -moz-text-fill-color: transparent;
}

.background-gradient {
  /* Fallback: Set a background color. */
  background-color: red;

  /* Create the gradient. */
  background-image: linear-gradient(45deg, #e04c8a, #5962f3);

  /* Set the background size and repeat properties. */
  background-size: 100%;
  background-repeat: repeat;
}

input[type='number']::-webkit-inner-spin-button,
input[type='number']::-webkit-outer-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

.custom-number-input input:focus {
  outline: none !important;
}

.custom-number-input button:focus {
  outline: none !important;
}

.balloon {
  @apply block;
  @apply absolute;
  @apply rounded;
  @apply z-50;
  @apply p-20px;
  @apply bg-white;

  box-shadow: 0 0 20px 4px rgba(55, 65, 81, 0.2);
  min-width: 60px;
  margin-top: 20px;
}

.balloon-corner {
  @apply block;
  @apply absolute;
  width: 40px;
  height: 20px;
  overflow: hidden;
  top: -20px;
  left: 30px;
}

.balloon-corner::before {
  @apply block;
  @apply absolute;
  @apply bg-white;
  @apply z-40;

  content: '';

  width: 20px;
  height: 20px;

  transform: rotate(45deg);
  left: 8px;
  bottom: -10px;
}

/* пре-подвальный текст */
.pre-footer-text {
  @apply text-13px;
  @apply text-gray-500;
}

.pre-footer-text strong {
  @apply font-medium;
  @apply text-gray-700;
}

.pre-footer-text a {
  @apply text-primary;
}

/* главная часть подвала */
.footer-main {
  @apply text-gray-700;
  @apply text-14px;
}

.footer-main-links a:hover {
  @apply text-primary;
}

/* подвал - текст с контактами */
.footer-contacts {
  @apply text-13px;
  @apply text-gray-500;
}

.footer-contacts strong {
  @apply font-medium;
  @apply text-gray-700;
}

.footer-contacts a {
  @apply text-gray-700;
}

.footer-contacts a:hover {
  @apply text-primary;
}

/* соглашение, политика обработки персональных данных, кредиты */
.footer-cred {
  @apply text-13px;
  @apply text-gray-500;
}

.footer-cred a:hover {
  @apply text-primary;
}

.footer-cred .cred-author {
  @apply whitespace-nowrap;
}

/* загрузка */
#nprogress {
  pointer-events: none;
}

#nprogress .bar {
  background: theme('colors.primary');

  position: fixed;
  z-index: 1031;
  top: 0;
  left: 0;

  width: 100%;
  height: 2px;
}

/* Fancy blur effect */
#nprogress .peg {
  display: block;
  position: absolute;
  right: 0;
  width: 100px;
  height: 100%;
  /* box-shadow: 0 0 10px theme('colors.primary'), 0 0 5px theme('colors.primary'); */
  opacity: 1;

  -webkit-transform: rotate(3deg) translate(0px, -4px);
  -ms-transform: rotate(3deg) translate(0px, -4px);
  transform: rotate(3deg) translate(0px, -4px);
}

/* Remove these to get rid of the spinner */
#nprogress .spinner {
  display: block;
  position: fixed;
  z-index: 1031;
  top: 15px;
  right: 15px;
}

#nprogress .spinner-icon {
  width: 18px;
  height: 18px;
  box-sizing: border-box;

  border: solid 2px transparent;
  border-top-color: theme('colors.primary');
  border-left-color: theme('colors.primary');
  border-radius: 50%;

  -webkit-animation: nprogress-spinner 400ms linear infinite;
  animation: nprogress-spinner 400ms linear infinite;
}

.nprogress-custom-parent {
  overflow: hidden;
  position: relative;
}

.nprogress-custom-parent #nprogress .spinner,
.nprogress-custom-parent #nprogress .bar {
  position: absolute;
}

@-webkit-keyframes nprogress-spinner {
  0% {
    -webkit-transform: rotate(0deg);
  }
  100% {
    -webkit-transform: rotate(360deg);
  }
}
@keyframes nprogress-spinner {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}

@tailwind utilities;

.outline {
  outline-style: none;
}
