.container {
  @apply flex items-center;
}

.icon {
}

.text {
  @apply ml-6px;
}
