.tagText {
  color: #302e30;
  text-overflow: ellipsis;
  overflow: hidden;
  white-space: nowrap;
}

.tag {
  font-size: 14px;
  line-height: 17px;
  display: flex;
  align-items: baseline;
  justify-content: center;
  background: #fafafa;
  border-radius: 3px;
  padding: 9px 20px;
  border: 1px solid transparent;
  box-sizing: border-box;

  cursor: pointer;

  &:hover {
    background: transparent;
    border-color: #e54e8d;
  }

  &.active {
    background: #e54e8d;
    border-color: #e54e8d;

    .tagText {
      color: white;
    }

    @media (max-width: 480px) {
      padding: 6px 12px;
      font-size: 13px;
      line-height: 18.2px;
    }
  }

  .count {
    color: #9ca0a9;
  }
}
